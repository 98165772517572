<template>
  <div class="admin_div">
    <div class="admin_ln">
      <div class="admin_ln_1">
        <div class="box1">活跃session数：{{GetinfoList.active_session}}</div>
        <div class="box1">
            <el-input
            v-model="Sendnotice.content"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 6}"
            placeholder="请输入通知内容"
            >
            </el-input>
        </div>
        <div class="box1">通知等级：
            <el-radio-group v-model="Sendnotice.rate">
                <el-radio :label="0">弹窗</el-radio>
                <el-radio :label="1">消息数标</el-radio>
            </el-radio-group>
        </div>
        <div class="box1"><el-input v-model="Sendnotice.username" placeholder="请输入用户名"></el-input></div>
        <div class="box1">备注：用户名为空时，对全体用户生效</div>
        <div class="box1"><div class="button" @click="getboardSendnotice()">发送</div></div>
      </div>

      <div class="admin_ln_1">
        <div class="box1">当前弹窗通知</div>
        <div class="box1" v-for="(item, index) in GetinfoList.push_list" :key="index">{{item.content}}<div class="button" @click="getboardStopnotice(item.timestamp)">停止弹窗</div></div>
      </div>

      <div class="admin_ln_2">
        <div class="box1">Error logs：</div>
        <div class="box1" v-for="(item, index) in GetinfoList.error_list" :key="index">{{item}}</div>
        <div class="box1">Warn logs：</div>
        <div class="box1" v-for="(item, index) in GetinfoList.warning_list" :key="index">{{item}}</div>
      </div>

      <div class="admin_ln_1">
        <div class="box1"><el-input v-model="Getuser.username" placeholder="请输入用户名"></el-input></div>
        <div class="box1"><div @click="getboardGetuser()" class="button">查询</div></div>
        <!-- <div class="box1" v-html="GetuserList"></div> -->
        <json-viewer :value="GetuserList" copyable theme="my-awesome-json-theme"></json-viewer>
      </div>
      
      <div class="admin_ln_1">
        <div class="box1">风控解除</div>
        <div class="box1"><el-input v-model="Dismissban.username" placeholder="请输入用户名"></el-input></div>
        <div class="box1"><el-input v-model="Dismissban.set_quota" placeholder="请输入风控quota"></el-input></div>
        <div class="box1"><div class="button" @click="getboardDismissban()">确定</div></div>
      </div>
    </div>
  </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer'
import {boardGetinfo,boardSendnotice,boardStopnotice,boardGetuser,boardDismissban} from "@/api/user";
export default {
    components:{
        JsonViewer
    },
  data() {
    return {
        Sendnotice:{
            "rate":"",
            "content":"",
            "username":"",
        },
        Getuser:{
            "username":""
        },
        GetuserList:"",
        GetinfoList:{
            active_session:""
        },
        Dismissban:{
            "username":"",
            "set_quota":"",
        }
    };
  },
  mounted() {
    var that = this;
    that.getboardGetinfo();
  },
  methods: {
    //停止
    getboardStopnotice(index) {
        var that = this;
        var Stopnotice={
            "msg_timestamp":index
        }
        boardStopnotice(Stopnotice).then((res) => {
            if(res.code==0){
                that.$message.success("停止成功");
                that.getboardGetinfo()
            }
        });
    },
    //风控解除
    getboardDismissban() {
        var that = this;
        boardDismissban(that.Dismissban).then((res) => {
            if(res.code==0){
                that.$message.success(res.result.message);
            }
        });
    },
    //获取用户日志
    getboardGetinfo() {
        var that = this;
        boardGetinfo().then((res) => {
            if(res.code==0){
                that.GetinfoList=res.result;
            }
        });
    },
    //获取用户
    getboardGetuser() {
        var that = this;
        boardGetuser(that.Getuser).then((res) => {
            if(res.code==0){
                that.GetuserList=res;
            }
        });
    },
    //发送通知
    getboardSendnotice() {
        var that = this;
        boardSendnotice(that.Sendnotice).then((res) => {
            if(res.code==0){
                that.$message.success("发送成功");
                that.getboardGetinfo()
            }
        });
    },
  },
};
</script>
<style lang="less" scoped></style>
